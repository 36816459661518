.claim-notes td {
  padding-top: 0;
}
.claim-notes td span {
  background: #f3f4f5;
  padding: 3px 5px;
  position: relative;
  border-radius: 5px;
  display: inline-block;
  font-size: 13px;
}
.claim-notes td span:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f3f4f5;
  top: -5px;
  left: 30px;
}