@import '../theme';
#login-header {
  padding: 20px 0;
}
#login-header > .container > .row {
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
#login-header > .container > .row > .col {
  flex: 1;
}
#login-header .nav-menu {
  margin-left: auto;
}
#login-header .nav {
  justify-content: flex-end;
}
#login-header .main-logo img {
  max-height: 40px;
}