td.lead-name .icon {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}
.lead-popup {
  background: #f3f4f5;
  border-radius: 10px;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 25px;
  z-index: 4;
  display: none;
  min-width: 400px;
}
.lead-name .icon:hover .lead-popup {
  display: block;
}
.lead-popup h5 {
  font-size: 18px;
  font-weight: bold;
}
.lead-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.lead-popup ul li {
  display: flex;
  justify-content: flex-start;
}
.lead-popup ul li > span:nth-child(1) {
  min-width: 120px;
  display: block;
}