#page-login {
    position: relative;
}
.login-form-box {
    max-width: 540px;
    margin: 80px auto;
}
.login-form-box form {
    margin-top: 30px;
    border-radius: 10px;
    border: 1px solid #e4e5e6;
    padding: 30px;
}
.signup-form-box {
    margin: 0;
}