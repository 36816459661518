@import '../theme';
body {
    background: #fff;
}
.page-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.page-inner {
    flex: 1;
    padding-top: 20px;
    padding-left: 30px;
}
.progress {
    height: 24px;
}
.chart-header {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    margin-bottom: 15px;
}
.chart-header > div > small,
.chart-header > div > strong {
    display: block;
}
.chart-header > div > small {
    font-size: 13px;
}
.chart-header > h5 {
    margin-right: auto;
}
@media screen and (max-width: 1180px) {
    .page-inner {
        padding-left: 0;
    }
}