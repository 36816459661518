@import '../theme';
#header {
  padding: 20px 0;
}
#header > .container > .row {
  justify-content: flex-start;
  align-items: center;
}
#header > .container > .row > .col {
  flex: 0;
}
#header .header-user-info {
  margin-left: auto;
}
#sidebar-toggle {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #000;
  font-size: 24px;
  padding: 0;
  margin: 0;
  display: none;
  position: relative;
}
#sidebar-toggle svg {
  vertical-align: top;
}
#header .main-logo img {
  max-height: 40px;
}
@media screen and (max-width: 1180px) {
  #sidebar-toggle {
    display: block;
    transition: none;
  }
  .page.menu-shown #sidebar-toggle {
    z-index: 10000;
    color: #fff !important;
  }
}
@media screen and (max-width: 768px) {
  #header .header-user-info {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    padding-bottom: 10px;
    flex: 1 1 100% !important;
    width: 100% !important;
  }
}