@import '../theme';
.wallet {
  background: $primary;
  color: $dark;
  border-radius: 10px;
  padding: 30px;
}
.wallet h4 {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.wallet-items {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 40px;
}
.wallet-item {
  flex: 1 0 calc(50% - 10px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.wallet-slim .wallet-item {
  flex: 1 1 100%;
}
.wallet-item svg {
  font-size: 32px;
}
.wallet-item span,
.wallet-item strong {
  display: block;
}
.wallet-item strong {
  font-size: 24px;
  font-weight: bolder;
}
.wallet-item button {
  display: block;
  width: 100%;
}